import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class Quote {
  constructor(item = {}) {
    return {
      quotes_number: item.quotes_number || '',
      quotes_name: item.quotes_name || '',
      syncing: item.syncing === '1',
      expiration_date: item.expiration_date ? moment(item.expiration_date).format(DATE_FORMAT) : '',
      monthly_budget: item.monthly_budget || '',
      created_by: item.created_by || '',
    }
  }
}
