<template>
  <app-collapse-item :title="`${title} (${totalRows})`" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <OpportunityProduct
      :default-per-page="5"
      @table-loaded="totalRows = $event.totalRows"
    />
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import OpportunityProduct from '@/views/heroai/opportunities/view/product/view.vue'

export default {
  components: {
    OpportunityProduct,
    AppCollapseItem,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: () => 'Products',
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      totalRows: 0,
    }
  },
}
</script>
