import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class Quote {
  constructor(item = {}) {
    return {
      invoice_number: item.invoice_number || '',
      invoice_date: item.invoice_date ? moment(item.invoice_date).format(DATE_FORMAT) : '',
      invoice_document_date: item.invoice_document_date ? moment(item.invoice_document_date).format(DATE_FORMAT) : '',
      invoice_amount: item.invoice_amount || '',
      payment_document: item.payment_document || '',
      payment_document_Date: item.payment_document_Date ? moment(item.payment_document_Date).format(DATE_FORMAT) : '',
      payment_document_amount: item.payment_document_amount || '',
    }
  }
}
