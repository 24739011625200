<template>
  <app-collapse-item :title="title" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="one_time_setup_fee"
          :value="formatMoney(campaign.one_time_setup_fee)"
          label="One Time Setup Fee"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="monthly_budget"
          :value="formatMoney(campaign.monthly_budget)"
          label="Monthly Budget"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="monthly_budget_3"
          :value="formatMoney(campaign.monthly_budget_3)"
          label="Monthly Budget/3"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="duration"
          v-model="campaign.duration"
          label="Duration"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="monthly_revenue_difference"
          :value="formatMoney(campaign.monthly_revenue_difference)"
          label="Monthly Revenue Difference"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="influencer_budget"
          :value="formatMoney(campaign.influencer_budget)"
          label="Influencer Budget"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="amount"
          :value="formatMoney(campaign.amount)"
          label="Amount"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="overspending_absorbed_by_herolead"
          v-model="campaign.overspending_absorbed_by_herolead"
          label="Overspendind absorebed by herolead"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="amount_inclusive_of_tax"
          :value="formatMoney(campaign.amount_inclusive_of_tax)"
          label="Amount inclusive of Tax"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="sf_gross_profit"
          :value="formatMoney(campaign.sf_gross_profit)"
          label="SF Gross Profit"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="sf_gross_margin"
          v-model="campaign.sf_gross_margin"
          label="SF Gross Margin"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="sf_gross_profit_per_month"
          :value="formatMoney(campaign.sf_gross_profit_per_month)"
          label="SF Gross Profit per Month"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="facebook_budget"
          :value="formatMoney(campaign.facebook_budget)"
          label="Facebook Budget"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="google"
          :value="formatMoney(campaign.google)"
          label="Google"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="other_media"
          :value="formatMoney(campaign.other_media)"
          label="Other Media"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="non_media"
          :value="formatMoney(campaign.non_media)"
          label="Non Media"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import Campaign from '@/models/Campaign'
import DataFormatService from '@/services/DataFormatService'

export default {
  name: 'Campaign',
  components: {
    AppCollapseItem,
    HeroDisplayText,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Campaign Details',
    },
    campaign: {
      type: Object,
      required: true,
      default: () => new Campaign(),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.formatMoney = DataFormatService.formatMoney
  },
}
</script>
