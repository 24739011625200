<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }}
      </span>
    </template>
    <b-form-textarea
      :id="id"
      v-model="formValue"
      :plaintext="true"
      :style="customStyle"
      :rows="rows"
      :max-rows="maxRows"
      :no-resize="noResize"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
  },
  props: {
    // Got value from v-model
    value: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    column: {
      type: String,
      required: false,
      default: '0',
    },
    rows: {
      type: String,
      required: false,
      default: '5',
    },
    maxRows: {
      type: String,
      required: false,
      default: '5',
    },
    noResize: {
      type: Boolean,
      required: false,
      default: false,
    },
    customStyle: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
