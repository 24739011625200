<template>
  <app-collapse-item :title="title" :isVisible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="stage"
          v-model="stage.stage"
          label="Stage"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="close_date"
          v-model="stage.close_date"
          label="Close Date"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="expected_duration"
          v-model="stage.expected_duration"
          label="Expected Duration"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="win_reason"
          v-model="stage.win_reason"
          label="Win Reason"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="probability"
          v-model="stage.probability"
          label="Probability (%)"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="days_take_to_close_deal"
          v-model="stage.day_take_to_close_deal"
          label="Days take to close deal"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="CountQuote"
          v-model="stage.loss_reason"
          label="Loss Reason"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="loss_reason_explanation"
          v-model="stage.loss_reason_explanation"
          label="Loss Reason Explanation"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="planned_start_date"
          v-model="stage.planned_start_date"
          label="Planned Start Date"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="planned_start_date"
          v-model="stage.planned_end_date"
          label="Planned End Date"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>
<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import Stage from '@/models/Opportunity/Stage'

export default {
  name: 'Stage',
  components: {
    AppCollapseItem,
    HeroDisplayText,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Stages',
    },
    stage: {
      type: Object,
      required: true,
      default: () => new Stage(),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
