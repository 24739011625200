<template>
  <app-collapse-item :title="title" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroCheckbox
          id="risk_assessment_completed_by_finance"
          v-model="dealTerms.risk_assessment_completed_by_finance"
          label="Risk Assessment Completed By Finance"
          :disabled="true"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroCheckbox
          id="general_term_of_service_signed"
          v-model="dealTerms.general_term_of_service_signed"
          label="General Term of Service Signed"
          :disabled="true"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="finance_suggested_payment_terms"
          v-model="dealTerms.finance_suggested_payment_terms"
          label="Finance Suggested Payment Terms"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="payment_terms"
          v-model="dealTerms.payment_terms"
          label="Payment Terms"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroCheckbox
          id="sla_approved_by_AM"
          v-model="dealTerms.sla_approved_by_AM"
          label="Non Std General Term Approved by AM"
          :disabled="true"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="overall_management_fee_in_percentage"
          v-model="dealTerms.overall_management_fee_in_percentage"
          label="INDO Overall Management Fee In %"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="model"
          v-model="dealTerms.model"
          label="Model"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import DealTerms from '@/models/Opportunity/DealTerms'

export default {
  name: 'OpportunityInformation',
  components: {
    AppCollapseItem,
    HeroDisplayText,
    HeroCheckbox,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Deal Terms (Sales must fill this)',
    },
    dealTerms: {
      type: Object,
      required: true,
      default: () => new DealTerms(),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
