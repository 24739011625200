import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class OpportunityCampaignData {
  constructor(item = {}) {
    return {
      campaign_id: item.campaign_id || '',
      name: item.name || '',
      period: item.duration || '',
      campaign_status: item.campaign_status || '',
      opportunity: item.opportunity || '',
      brief_date: item.brief_date ? moment(item.brief_date).format(DATE_FORMAT) : '',
      actual_campaign_start_date: item.actual_campaign_start_date ? moment(item.actual_campaign_start_date).format(DATE_FORMAT) : '',
      actual_campaign_end_date: item.actual_campaign_end_date ? moment(item.actual_campaign_end_date).format(DATE_FORMAT) : '',
      paused_date: item.paused_date ? moment(item.paused_date).format(DATE_FORMAT) : '',
      unpaused_date: item.unpaused_date ? moment(item.unpaused_date).format(DATE_FORMAT) : '',
      day_left: item.day_left || '',
      kpi: item.kpi || '',
      per_cycle_budget: item.per_cycle_budget || '',
      alpha_campaign_id: item.alpha_campaign_id || '',
      google_account_id: item.google_account_id || [],
      facebook_account_id: item.facebook_account_id || [],
      tiktok_account_id: item.tiktok_account_id || [],
      pricing_model: item.pricing_model || '',
      // bonus_discount: item.bonus_discount || '',
      // landing_page_type: item.landing_page_type || '',
      // landing_page_language: item.landing_page_language || '',
      // uniqued_selling_point: item.uniqued_selling_point || '',
      // service_focus: item.service_focus || '',
      // keyword_ideas: item.keyword_ideas || '',
      // did_phone: item.did_phone || [],
      // creator: item.creator || '',
      // remark: item.remark || '',
      industry: item.industry || '',
      // media_plan: item.media_plan || '',
      objectives: item.objectives || '',
      country_code: item.country_code || '',
      // line_notifications: item.line_notifications || '',
      // line_notifications_language: item.line_notifications_language || '',
      // viewers: item.viewers || [],
      // demo_campaign: item.demo_campaign || false,
    }
  }
}
