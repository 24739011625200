import OpportunityCampaign from '@/models/Opportunity/OpportunityCampaign'

export default class OpportunityCampaignFactory {
  static createFromJson(json) {
    return new OpportunityCampaign(json)
  }

  static createFromJsonArray(jsonArray = []) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(OpportunityCampaignFactory.createFromJson(item))
    })

    return jsonData
  }
}
