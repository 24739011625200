import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class Quote {
  constructor(item = {}) {
    return {
      title: item.title || '',
      text_preview: item.text_preview || '',
      created_by: item.created_by || '',
      last_modified: item.last_modified ? moment(item.last_modified).format(DATE_FORMAT) : '',
      last_modified_by: item.last_modified_by || '',
    }
  }
}
