import Quote from '@/models/Opportunity/Quote'

export default class QuoteFactory {
  static createFromJson(json) {
    return new Quote(json)
  }

  static createFromJsonArray(jsonArray = []) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(QuoteFactory.createFromJson(item))
    })

    return jsonData
  }
}
