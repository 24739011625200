<template>
  <app-collapse-item :title="title" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="opportunity_name"
          v-model="information.opportunity_name"
          label="Opportunity Name"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="account_name"
          v-model="information.account_name"
          label="Account Name"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="client_legal_name"
          v-model="information.client_legal_name"
          label="Client Legal Name"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="type"
          v-model="information.type"
          label="Type"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="lead_source"
          v-model="information.lead_source"
          label="Lead Source"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="lead_source_2"
          v-model="information.lead_source_2"
          label="Lead Source 2"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="marketing_activity"
          v-model="information.marketing_activity"
          label="Marketing Activity"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="marketing_objective"
          v-model="information.marketing_objective"
          label="Marketing Objective"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="nature_of_deal"
          v-model="information.nature_of_deal"
          label="Nature of Deal"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="campaign_name"
          v-model="information.campaign_name"
          label="Campaign Name"
        />
      </b-col>
      <!-- <b-col cols="12" md="3">
        <HeroDisplayText
          id="campaign_code"
          v-model="information.campaign_code"
          label="Campaign Code"
        />
      </b-col> -->
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="campaign_id"
          v-model="information.campaign_id"
          label="Sf Campaign ID"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="opportunity_id"
          v-model="information.opportunity_id"
          label="Opportunity ID"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="tax_id"
          v-model="information.tax_id"
          label="Tax ID"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="synced_quote_id"
          v-model="information.synced_quote_id"
          label="Synced Quote ID"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="synced_quote"
          v-model="information.synced_quote"
          label="Synced Quote"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>
<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import Information from '@/models/Opportunity/Information'
import BreadCrumbMixin from '@/mixins/breadcrumb'

export default {
  name: 'OpportunityInformation',
  components: {
    AppCollapseItem,
    HeroDisplayText,
  },
  mixins: [BreadCrumbMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: 'Opportunity Information',
    },
    information: {
      type: Object,
      required: true,
      default: () => new Information(),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.setBreadCrumb(this.information.opportunity_name)
  },
}
</script>
