<template>
  <app-collapse-item :title="title" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayTextarea
          id="client_requirements"
          v-model="client_billing.client_requirements"
          label="Client Requirements"
          rows="0"
          max-rows="10"
          :no-resize="true"
        />
      </b-col>
      <b-col cols="12" md="3">
          <HeroDisplayTextarea
            id="finance_process"
            v-model="client_billing.finance_process"
            label="Finance Process"
            rows="5"
            max-rows="5"
            :no-resize="true"
          />
      </b-col>
      <b-col cols="12" md="3">
          <HeroDisplayText
            id="invoice_placement_date_for_cycle_1"
            v-model="client_billing.invoice_placement_date_for_cycle_1"
            label="Invoice Placement Date for Cycle 1"
          />
      </b-col>
      <b-col cols="12" md="3">
          <HeroDisplayText
            id="invoice_payment_date_for_cycle_1"
            v-model="client_billing.invoice_payment_date_for_cycle_1"
            label="Invoice Payment Date for Cycle 1"
          />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import HeroDisplayTextarea from '@/views/components/form/displays/HeroDisplayTextarea.vue'
import ClientBillingProcess from '@/models/Opportunity/ClientBillingProcess'

export default {
  name: 'OpportunityInformation',
  components: {
    AppCollapseItem,
    HeroDisplayText,
    HeroDisplayTextarea,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Client Billing Process',
    },
    client_billing: {
      type: Object,
      required: true,
      default: () => new ClientBillingProcess(),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
