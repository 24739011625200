<template>
  <app-collapse-item :title="title" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="4">
        <HeroCheckbox
          id="contract_approved_by_validators"
          v-model="validations.contract_approved_by_validators"
          label="Contract approved by Validators"
          :disabled="true"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroCheckbox
          id="pnd_documents_approved_by_finance"
          v-model="validations.pnd_documents_approved_by_finance"
          label="PND Documents Approved by Finance"
          :disabled="true"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroCheckbox
          id="custemer_paid_for_pia_pt_granted"
          v-model="validations.custemer_paid_for_pia_pt_granted"
          label="Customer paid for PIA/PT Granted"
          :disabled="true"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'

export default {
  name: 'OpportunityInformation',
  components: {
    AppCollapseItem,
    BRow,
    BCol,
    HeroCheckbox,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Validations (Validators must approve this)',
    },
    validations: {
      type: Object,
      required: true,
      default: () => {},
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
