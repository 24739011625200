import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class Files {
  constructor(item = {}) {
    return {
      title: item.title || '',
      created_date: item.last_modified ? moment(item.last_modified).format(DATE_FORMAT) : '',
      size: item.size || '',
      format: item.format || '',
      thumbnail: item.thumbnail || '',
    }
  }
}
