<template>
  <app-collapse-item :title="title" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="Opportunity Owner"
          v-model="incharge.owner"
          label="Opportunity Owner"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="OMC1"
          v-model="incharge.omc1"
          label="OMC1"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="OMC2"
          v-model="incharge.omc2"
          label="OMC2"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="OMC2%"
          v-model="incharge.omc2_percentage"
          label="OMC2%"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="Target"
          v-model="incharge.target"
          label="Target"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="ParentOpportunity"
          v-model="incharge.parent_opportunity"
          label="Parent Opportunity"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="Industry"
          v-model="incharge.industry"
          label="Industry"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="est_rebate"
          v-model="incharge.est_rebate"
          label="Est. Rebate %"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="ClientSegment"
          v-model="incharge.client_segment"
          label="Client Segment"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="LegalEntity"
          v-model="incharge.legal_entity"
          label="Legal Entity"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="CountQuote"
          v-model="incharge.count_quote"
          label="Count Quote"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="AccountManager"
          v-model="incharge.account_manager"
          label="Account Manager"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="order_number"
          v-model="incharge.order_number"
          label="Order Number"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="currency"
          v-model="incharge.currency"
          label="Opportunity Currency"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="forecast_category"
          v-model="incharge.forecast_category"
          label="Forecast Category"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="role_custom"
          v-model="incharge.role_custom"
          label="Role Custom"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>
<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import Incharge from '@/models/Opportunity/Incharge'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayText,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Who is in charge?',
    },
    incharge: {
      type: Object,
      required: true,
      default: () => new Incharge(),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
