<template>
  <app-collapse-item :title="`${title} (${tableConfig.totalRows})`" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-overlay no-wrap />
    <b-card ref="cardA" class="mb-2" no-body @refresh="doLoadData('cardA')">
      <!-- Sales History Table -->
      <div class="mx-1">
        <b-row>
          <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
            <HeroInputText
              id="filter"
              v-model="tableConfig.filter"
              placeholder="Search"
              @input="doTableFilter(500)"
            />
          </b-col>
          <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2" />
        </b-row>
      </div>
      <!-- Sales History Table -->
      <b-row class="my-1">
        <b-col cols="12">
          <b-table class="mt-2"
                   show-empty
                   striped
                   sticky-header="100%"
                   :responsive="true"
                   :items="items"
                   :per-page="0"
                   :sort-by="tableConfig.sortBy"
                   :sort-direction="tableConfig.sortDirection"
                   :fields="tableConfig.fields"
                   :no-sort-reset="true"
                   :no-local-sorting="true"
                   @sort-changed="doTableSort"
          >
            <!-- <template #cell(index)="data">
          {{ data.index + 1 }}
        </template> -->
            <template #cell(order_date)="data">
              {{ formatUsaDate(data.item.order_date) }}
            </template>
            <template #cell(due_date)="data">
              {{ formatUsaDate(data.item.due_date) }}
            </template>
            <template #cell(total_amount)="data">
              <div class="text-right">
                {{ formatMoney(data.item.total_amount) }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import moment from 'moment'
import ErrorService from '@/services/ErrorService'
import SalesHistoryFactory from '@/factories/Opportunity/SalesHistory'
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import DataFormatService from '@/services/DataFormatService'
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BOverlay,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    AppCollapseItem,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: () => 'Sales History',
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showOverlay: true,
      items: [],
      total: null,
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 5,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'invoice_number',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          { key: 'invoice_number', label: 'Invoice No.', sortable: true },
          { key: 'total_amount', label: 'Total Amount', sortable: true },
          { key: 'due_date', label: 'Due Date', sortable: true },
          { key: 'quote_number', label: 'Quote No.', sortable: true },
          { key: 'order_date', label: 'Order Date', sortable: true },
          { key: 'payment_terms', label: 'Payment Terms Description', sortable: true },
        ],
      },
    }
  },
  created() {
    this.formatUsaDate = DataFormatService.formatUsaDate
    this.formatMoney = DataFormatService.formatMoney
  },
  async mounted() {
    await this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.cardA.showLoading = true

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const qparam = {
          perPage, page: currentPage, q: filter, orderByColumn: sortBy, orderByDirection: sortDirection, opportunity_id: this.$route.params.id,
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceOpportunitySalesHistory, qparam)
        if (response.status === true) {
          this.tableConfig.currentPage = Number(response.data.current_page)
          this.tableConfig.perPage = Number(response.data.per_page)
          this.tableConfig.totalRows = Number(response.data.total)
          this.items = SalesHistoryFactory.createFromJsonArray(response.data.data)
        } else {
          this.tableConfig.currentPage = 1
          this.tableConfig.totalRows = 0
          this.items = []
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        this.$refs.cardA.showLoading = false
      }
    },
  },
}
</script>
