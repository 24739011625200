import Note from '@/models/Opportunity/Note'

export default class NoteFactory {
  static createFromJson(json) {
    return new Note(json)
  }

  static createFromJsonArray(jsonArray = []) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(NoteFactory.createFromJson(item))
    })

    return jsonData
  }
}
