export default class OpportunityState {
  constructor() {
    return {
      Incharge: false,
      Stage: false,
      Information: false,
      DealTerms: false,
      Validation: false,
      ClientBilling: false,
      CampaignDetail: false,
      AccountManagementInfo: false,
      SystemInformation: false,
      SalesOrder: false,
      SalesHistory: false,
      Notes: false,
      Files: false,
      Quotes: false,
      InvoicePayments: false,
      Products: false,
    }
  }
}
