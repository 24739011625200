<template>
  <app-collapse-item :title="title" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayLink
          id="heroai_link"
          v-model="account_management_info.heroai_link"
          label="HeroAI Link"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="account_management_status"
          v-model="account_management_info.account_management_status"
          label="Account Management Status"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="actual_campaign_start_date"
          v-model="account_management_info.campaign_start_date"
          label="Actual Campaign Start Date"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="actual_campaign_end_date"
          v-model="account_management_info.campaign_end_date"
          label="Actual Campaign End Date"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayLink
          id="sayonara_ticket"
          v-model="account_management_info.sayonara_ticket"
          label="Sayonara Ticket"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="client_at_risk_stage"
          v-model="account_management_info.client_at_risk_stage"
          label="Client At Risk Stage"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="reason_for_high_risk"
          v-model="account_management_info.reason_for_high_risk"
          label="Reason For High Risk"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="churn_reason"
          v-model="account_management_info.churn_reason"
          label="Churn Reason"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="churn_explanation"
          v-model="account_management_info.churn_explanation"
          label="Churn Explanation"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="churn_date"
          v-model="account_management_info.churn_date"
          label="Churn Date"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import HeroDisplayLink from '@/views/components/form/displays/HeroDisplayLink.vue'
import AccountManagementInfo from '@/models/Opportunity/AccountManagementInfo'

export default {
  name: 'AccountManagementInfo',
  components: {
    AppCollapseItem,
    HeroDisplayText,
    HeroDisplayLink,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Account Management Info',
    },
    account_management_info: {
      type: Object,
      required: true,
      default: () => new AccountManagementInfo(),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
