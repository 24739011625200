<template>
  <app-collapse-item :title="title" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="created_by"
          v-model="system_information.created_by"
          label="Created By"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="last_modified_by"
          v-model="system_information.last_modified_by"
          label="Last Modified By"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="latest_email_notification"
          v-model="system_information.latest_email_notification"
          label="Latest Email Notification"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import HeroDisplayLink from '@/views/components/form/displays/HeroDisplayLink.vue'
import SystemInformation from '@/models/Opportunity/SystemInformation'

export default {
  name: 'SystemInformation',
  components: {
    AppCollapseItem,
    HeroDisplayText,
    HeroDisplayLink,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'System Information',
    },
    system_information: {
      type: Object,
      required: true,
      default: () => new SystemInformation(),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
